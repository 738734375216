import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import Hero from "../../../components/hero";
import ContactPerson from "../../../components/contactPerson";
import { CaseList } from "../../../components/caseList";
import { Facts } from "../../../components/facts";
import { Count } from "../../../components/count";
import { Container } from "../../../components/container";
import { ServicesTeaser } from "../../../components/servicesTeaser";
import { PartnersGrid } from "../../../components/partnersGrid";

const CoremediaPartner = () => {
  const images = useStaticQuery(graphql`
    {
      hero: file(relativePath: { eq: "hero-coremedia.jpg" }) {
        childImageSharp {
          gatsbyImageData(quality: 80, layout: FULL_WIDTH)
        }
      }
      coremediagold: file(relativePath: { eq: "partner/coremedia-gold.png" }) {
        ...pngTracedLogo
      }
      coremediacertified: file(
        relativePath: { eq: "partner/coremedia-certified.png" }
      ) {
        ...pngTracedLogo
      }
      nicole: file(relativePath: { eq: "profiles/nicole-reske.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
        }
      }
    }

    fragment pngTracedLogo on File {
      childImageSharp {
        gatsbyImageData(width: 380, placeholder: NONE, layout: CONSTRAINED)
      }
    }
  `);

  return (
    <Layout lang="de" langPath="partner.coremedia.link">
      <Seo
        title="CoreMedia Implementierungspartner – Monday Consulting"
        description="Als “Gold- und Certified Partner” von CoreMedia setzen wir  mit dem agilen CMS maßgeschneiderte Plattformen um und integrieren sie in bestehende Systeme."
        keywords="CoreMedia, Implementierungspartner, CMS, CMCC, DXP, Gold Partner, Headless CMS"
      />
      <div className="parallax-translate bg-shape-dots"></div>
      <Hero
        headline="CoreMedia – die intuitive Experience Platform"
        subline="Seit 2009 setzen wir auf die smarte DXP von CoreMedia und implementieren Lösungen auf den Punkt."
        section="CoreMedia Partner"
        styleClass="mb-lg-5"
        bgColor="green"
      >
        <GatsbyImage
          image={images.hero.childImageSharp.gatsbyImageData}
          className="hero-image"
          alt="CoreMedia"
        />
      </Hero>

      <div className="container mb-4 mb-md-5 mt-md-5 my-5">
        <div className="row flex-row-reverse">
          <div className="col-12 col-md-8 mb-4">
            <h2>Expertise, die Vertrauen schafft</h2>
            <p>
              Das Herzstück von CoreMedia ist ein agiles
              Content-Management-System, das es ermöglicht, Inhalte aus
              verschiedenen Quellen zu speichern und sie zu einem ansprechenden
              digitalen Erlebnis für jedes Gerät und jede Plattform
              auszuspielen.
            </p>
            <p>
              Bereits seit 2009 bauen wir auf das agile CMS von CoreMedia und
              setzen als „Gold Partner” maßgeschneiderte Plattformen für unsere
              Kunden um und integrieren sie in bestehende Systeme. Das
              Content-Management-System bietet die größtmögliche Flexibilität,
              um Lösungen auf den Punkt zu entwickeln. Ob Kunden- oder
              Mitarbeiterportal, unsere CMS-Lösungen basierend auf CoreMedia
              transportieren Informationen und vernetzen Menschen und Prozesse.
            </p>
            <p>
              Als „Certified Partner” sind wir mit allen verfügbaren
              Zertifikaten der gesamten CoreMedia-Plattform ausgestattet, um
              Ihre Lösung professionell zu implementieren und an Ihre
              spezifischen Anforderungen anzupassen. Mit unseren Consultants und
              dem CoreMedia-CMS gehen Sie flexibel und sicher in die Zukunft -
              unabhängig davon, welchen Touchpoint Sie mit dem System bedienen
              wollen, um Ihre Kunden zu erreichen.
            </p>
          </div>
          <div className="col-12 col-md-4 px-4 d-flex flex-column align-items-center">
            <div className="w-75 mb-3">
              <GatsbyImage
                image={images.coremediagold.childImageSharp.gatsbyImageData}
                className="w-100"
                alt="CoreMedia Gold Partner"
                title="CoreMedia Gold Partner"
              />
            </div>
            <div className="w-75 mb-3">
              <GatsbyImage
                image={
                  images.coremediacertified.childImageSharp.gatsbyImageData
                }
                className="w-100"
                alt="CoreMedia Certified Partner"
                title="CoreMedia Certified Partner"
              />
            </div>
          </div>
        </div>
      </div>

      <Container shape="square">
        <div className="container mt-6 mb-4 mb-md-5">
          <h2 className="col-12 text-center">
            Gute Gründe für Monday Consulting und CoreMedia
          </h2>
          <Facts>
            <Count count="100" suffix="%">
              Alle CoreMedia-Zertifikate im Unternehmen vorhanden.
            </Count>
            <Count count="2009">
              Langjährige CoreMedia-Partnerschaft seit 2009.
            </Count>
            <Count count="50" prefix=">">
              Mehr als 50 Kunden konnten wir mit CoreMedia-Projekten begeistern.
            </Count>
          </Facts>
        </div>
      </Container>

      <div className="container">
        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-cross bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              1
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Best-of-Breed</h2>
            <h3>Hybrid Headless CMS</h3>
            <p>
              Mit der CoreMedia Content Cloud schließen Sie die Lücke zwischen
              Marketing und Entwicklung. In der intuitiven
              Studio-Benutzeroberfläche bearbeiten Sie Ihre Inhalte bequem in
              der Live-Preview. Gleichzeitig stehen Ihnen mit dem
              leistungsfähigen Headless-CMS eine Vielzahl an modernen Features
              zur Verfügung. Zum Beispiel ein Advanced Asset Management und eine
              GraphQL-Anbindung.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-dots bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              2
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">Alles aus einer Hand</h2>
            <h3>Multiexperience Orchestration</h3>
            <p>
              Die CoreMedia Content Cloud wurde insbesondere für globale
              Omnichannel-Erlebnisse entwickelt. Sie verwalten Ihre Inhalte
              bequem in einem einzigen System und können sie problemlos über
              alle Kanäle hinweg für alle Regionen und Sprachen wiederverwenden
              und gezielt publizieren.
            </p>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 flex-md-row-reverse reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-circle bg-position-tl bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue">
              3
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Professionelles Formularmanagement
            </h2>
            <h3>Formcentric für CoreMedia</h3>
            <p>
              Unser Formularmanager Formcentric ist nahtlos in das CoreMedia
              Studio integriert. Erweitern Sie Ihren Webauftritt um
              professionelle HTML-Formulare, die sich optimal in Ihr Design
              einfügen. So erarbeiten Sie im Handumdrehen eine einzigartige
              Customer Experience.
            </p>
            <a
              href="https://formcentric.com/de/"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              Mehr zu Formcentric
            </a>
          </div>
        </div>

        <div className="row mb-5 mb-md-6 reveal">
          <div className="col-12 pb-4 mb-md-0 col-md-5 bg-shape-square bg-position-tr bg-size-50">
            <span className="countable text-size-0 text-color-monday-skyblue text-right">
              4
            </span>
          </div>
          <div className="col-12 col-md-7">
            <h2 className="h5 text-color-monday-green">
              Detailsuche im CoreMedia Studio
            </h2>
            <h3>Advanced Studio Search</h3>
            <p>
              Die von uns entworfene Advanced Studio Search erweitert das
              CoreMedia Studio um die Funktion „Detailsuche“ und bringt so die
              ehemalige Recherche aus dem Sitemanager zurück. Durch die einfache
              Auswahl und Verknüpfung von Inhaltseigenschaften können Sie den
              gesamten CMS-Content gezielt und detailliert durchsuchen.
            </p>
            <a
              href="https://marketplace.coremedia.com/de/addon/advanced-studio-search-62910"
              target="_blank"
              className="btn mt-1"
              rel="noopener noreferrer"
            >
              Mehr zur Advanced Studio Search
            </a>
          </div>
        </div>
      </div>

      <PartnersGrid
        title="Spezialisierung für Ihren Erfolg"
        teaser={
          "Mit unseren starken Partnerschaften gestalten wir barrierefreie und individuelle Erlebnisse von morgen."
        }
      />

      <ServicesTeaser
        title="Entdecken Sie unsere Möglichkeiten"
        text={
          "Mit unserer Expertise in Strategie und Konzeption gestalten wir gemeinsam optimale Lösungen, die heute die Standards von morgen erfüllen.\nVon der Auswahl des idealen Content-Management-Systems über einzigartiges Design und Kreation bis hin zur Optimierung von Frontend, Backend und Infrastruktur – wir bieten Ihnen alles aus einer Hand.\n\nWir begleiten Sie durch den gesamten Prozess und stehen auch nach Projektabschluss mit erstklassigem Customer Care zur Seite."
        }
        ctaLink="/leistungen/leistungsportfolio"
        ctaText="Unsere Leistungen im Überblick"
      />

      <div
        className="content-container mt-5 vertical-spacing bg-monday-skyblue bg-pattern-vertigo text-color-white text-center"
        data-spacing-bottom="cases"
      >
        <div className="container pt-4 pt-md-5 pb-3 reveal">
          <div className="row">
            <div className="col-12 d-flex flex-column align-items-center">
              <h3 className="mb-3">Ausgewählte CoreMedia-Referenzen</h3>
            </div>
          </div>
        </div>
      </div>
      <div
        id="cases"
        className="container vertical-offset reveal mb-md-6 mb-5"
        data-offset="50%"
      >
        <CaseList category="coremedia" count="2" />
      </div>
      <ContactPerson
        headline="Legen Sie Ihr CoreMedia-Projekt in unsere Hände."
        text="Für unsere Experten ist keine Herausforderung zu groß. Teilen Sie uns noch heute Ihre Wünsche und Anforderungen mit."
        contactEmail="coremedia@monday-consulting.com"
        contactPhone="+49 40 22 86 842 0"
        contactName="Nicole Reske, Unit-Leiterin CoreMedia"
      >
        <GatsbyImage
          image={images.nicole.childImageSharp.gatsbyImageData}
          alt="Nicole Reske"
        />
      </ContactPerson>
    </Layout>
  );
};

export default CoremediaPartner;
